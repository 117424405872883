import { BorrowerType, LoanType, Sector } from './types';

export const borrowerTypeLabels = {
  [BorrowerType.PrivateCorporate]: 'Private corporate',
  [BorrowerType.PrivateCorporateSponsorBacked]:
    'Private corporate (sponsor-backed)',
  [BorrowerType.PublicCorporate]: 'Public corporate',
  [BorrowerType.PublicCorporateSponsorBacked]:
    'Public corporate (sponsor-backed)',
  [BorrowerType.GeneralPartner]: 'General partner',
  [BorrowerType.LimitedPartner]: 'Limited partner',
  [BorrowerType.PartnershipFund]: 'Partnership (fund)',
  [BorrowerType.Government]: 'Government',
  [BorrowerType.Other]: 'Other',
};

export const loanTypeLabels = {
  [LoanType.AssetBasedLending]: 'Asset Based Lending',
  [LoanType.CreditLines]: 'Credit Lines',
  [LoanType.GpFinancing]: 'GP financing',
  [LoanType.LpFinancing]: 'LP financing',
  [LoanType.NavFinancing]: 'NAV financing',
  [LoanType.LenderFinance]: 'Lender Finance',
  [LoanType.RevenueBasedLending]: 'Revenue Based Lending',
  [LoanType.StructuredProduct]: 'Structured product',
  [LoanType.TermLoan]: 'Term Loan',
  [LoanType.TradeFinance]: 'Trade Finance',
  [LoanType.Syndication]: 'Syndication',
  [LoanType.SpecialSituation]: 'Special situation',
  [LoanType.DistressedDebt]: 'Distressed debt',
  [LoanType.VentureDebt]: 'Venture debt',
  [LoanType.Other]: 'Other',
};

export const sectorLabels = {
  [Sector.Agriculture]: 'Agriculture',
  [Sector.BuildingProducts]: 'Building Products',
  [Sector.BusinessServices]: 'Business Services',
  [Sector.Chemicals]: 'Chemicals',
  [Sector.CleanEnergy]: 'Clean Energy',
  [Sector.Coal]: 'Coal',
  [Sector.Construction]: 'Construction',
  [Sector.ConsumerRetail]: 'Consumer & Retail',
  [Sector.Education]: 'Education',
  [Sector.EnergyNaturalResources]: 'Energy / Natural Resources',
  [Sector.Fig]: 'FIG',
  [Sector.Healthcare]: 'Healthcare',
  [Sector.IndustrialsManufacturing]: 'Industrials & manufacturing',
  [Sector.Infrastructure]: 'Infrastructure',
  [Sector.Insurance]: 'Insurance',
  [Sector.LifeScience]: 'Life Science',
  [Sector.MediaCommunications]: 'Media & Communications',
  [Sector.Mining]: 'Mining',
  [Sector.Other]: 'Other',
  [Sector.RealEstate]: 'Real Estate',
  [Sector.Shipping]: 'Shipping',
  [Sector.SportEntertainment]: 'Sport & Entertainment',
  [Sector.TechnologySoftware]: 'Technology & Software',
  [Sector.Transportation]: 'Transportation',
};
