import { useCallback } from 'react';
import Card from '@mui/material/Card';
import {
  ChannelMessagePersistenceType,
  ChannelMessageType,
  SendChannelMessageCommand,
} from '@aws-sdk/client-chime-sdk-messaging';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ChatChannel,
  useChannelsStateUpdate,
  useGetChatSessionQuery,
  useMessagesList,
  useMessagingSession,
  useSetupUsersColors,
} from 'src/entities/chat';
import { useIntersectionObserver } from 'src/hooks';
import ChatMessagesInput from './ChatMessagesInput';
import ChatMessagesList from './ChatMessagesList';
import ChatMessagesHeader from './ChatMessagesHeader';

type Props = {
  channel?: ChatChannel;
};

const style = {
  p: 2,
  mb: 2,
  gap: 2,
  flex: 1,
  height: '100%',
};

function ChatMessages({ channel }: Props) {
  const { showAlert } = useAlert();
  const { data, isFetching: isSessionLoading } = useGetChatSessionQuery();
  const { client, session } = useMessagingSession(
    !isSessionLoading ? data : undefined,
  );
  const params = {
    channelArn: channel?.channelSummary.channelArn,
    userArn: data?.userArn,
    client,
    session,
  };
  const { messages, loadMessages, isLoading } = useMessagesList(params);
  const lastElementRef = useIntersectionObserver<HTMLLIElement>(loadMessages);

  useChannelsStateUpdate({ ...params, hasMessages: !!messages?.length });
  useSetupUsersColors(messages, data?.userArn);

  const handleSendMessage = useCallback(
    (value: string) => {
      const command = new SendChannelMessageCommand({
        Type: ChannelMessageType.STANDARD,
        Persistence: ChannelMessagePersistenceType.PERSISTENT,
        ChannelArn: channel?.channelSummary.channelArn,
        ChimeBearer: data?.userArn,
        Content: value,
      });
      client
        ?.send(command)
        .catch(() =>
          showAlert({ type: AlertType.Error, text: 'Failed to send message' }),
        );
    },
    [channel, data, client, showAlert],
  );

  return (
    <Card sx={style}>
      <ChatMessagesHeader channel={channel} />
      <ChatMessagesList
        isLoading={isSessionLoading || isLoading}
        messages={messages}
        userArn={data?.userArn}
        lastElementRef={lastElementRef}
      />
      <ChatMessagesInput
        disabled={channel?.channelSummary.readonly}
        onSend={handleSendMessage}
      />
    </Card>
  );
}

export default ChatMessages;
