import {
  ChannelMessageSummary,
  ChimeSDKMessagingClient,
  ChimeSDKMessagingClientConfig,
} from '@aws-sdk/client-chime-sdk-messaging';
import {
  ConsoleLogger,
  DefaultMessagingSession,
  LogLevel,
  MessagingSessionConfiguration,
} from 'amazon-chime-sdk-js';
import {
  compareDatesAsc,
  formatDateTimeDynamically,
  getObjMinOccurEntry,
  monthsToYears,
} from 'src/utils';
import {
  ChatChannel,
  ChatChannelState,
  ChatRequestorType,
  ChatUserAvatarColor,
  ChatUsersState,
} from './types';
import { loanTypeLabels } from '../dicts';

export const getMessagingClient = (params?: ChimeSDKMessagingClientConfig) => {
  const config = structuredClone(params);
  return config && new ChimeSDKMessagingClient(config);
};

export const getMessagingSession = (
  client?: ChimeSDKMessagingClient,
  userArn?: string,
) => {
  if (client && userArn) {
    const sessionConfig = new MessagingSessionConfiguration(
      userArn,
      null,
      undefined,
      client,
    );
    const logger = new ConsoleLogger('SDK Chat', LogLevel.INFO);
    return new DefaultMessagingSession(sessionConfig, logger);
  }
  return undefined;
};

export const getChannelDataByArn = (
  items?: ChatChannel[],
  channelArn?: string | null,
) => ({
  data: items?.find(
    ({ channelSummary }) => channelSummary.channelArn === channelArn,
  ),
});

export const getChannelDisplayName = (channel?: ChatChannel) => {
  if (channel) {
    const { channelSubjectDto, channelSummary } = channel;
    const { lastMessageTimestamp } = channelSummary;
    const {
      borrowerName,
      creditorName,
      loanApplication,
      loanApplicationName,
      requestorType,
    } = channelSubjectDto;
    const { loanTerm, loanTypeCode, regionCode } = loanApplication;
    const loanType = loanTypeLabels[loanTypeCode] || loanTypeCode;
    const name =
      requestorType === ChatRequestorType.Borrower
        ? creditorName
        : borrowerName;
    const helperText = `${monthsToYears(
      loanTerm,
    )} years, ${regionCode}, ${loanType}`;
    const updatedAt =
      lastMessageTimestamp &&
      `Last updated ${formatDateTimeDynamically(lastMessageTimestamp)}`;

    return { name, helperText, description: loanApplicationName, updatedAt };
  }
  return {};
};

export const getCurrentChannels = (
  state: Record<string, ChatChannelState>,
  channels?: ChatChannel[],
) =>
  channels
    ?.map((channelItem) => {
      const channel = structuredClone(channelItem);
      const { channelArn, lastMessageTimestamp: currLastMsgTimestamp } =
        channel.channelSummary;
      const channelState = state[channelArn];
      if (channelState) {
        const { hasNewMessages, lastMessageTimestamp } = channelState;
        channel.userMembershipSummaryDto.hasNewMessages = hasNewMessages;
        channel.channelSummary.lastMessageTimestamp =
          lastMessageTimestamp ?? currLastMsgTimestamp;
      }
      return channel;
    })
    .sort((channelA, channelB) =>
      compareDatesAsc(
        channelB.channelSummary.lastMessageTimestamp,
        channelA.channelSummary.lastMessageTimestamp,
      ),
    );

export const getUserArnsFromMsgList = (
  messages: ChannelMessageSummary[],
  ownUserArn?: string,
) =>
  messages
    .map(({ Sender }) => Sender?.Arn)
    .filter((value): value is string => !!value && value !== ownUserArn);

export const getUpdatedUsersColors = (
  currentColors: ChatUsersState,
  userIds: string[],
) =>
  userIds.reduce((colors, userId) => {
    if (!currentColors[userId]) {
      const [color] = getObjMinOccurEntry(
        currentColors,
        Object.values(ChatUserAvatarColor),
      ) || [ChatUserAvatarColor.Dark];
      return { ...colors, [userId]: color };
    }
    return colors;
  }, currentColors);
