import { useCallback, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import ErrorIcon from 'src/assets/icons/error-icon.svg?react';
import SendIcon from 'src/assets/icons/send-icon.svg?react';
import Tooltip from 'src/components/Tooltip';

type Props = {
  disabled?: boolean;
  onSend?: (message: string) => void;
};

function ChatMessagesInput({ disabled, onSend }: Props) {
  const [value, setValue] = useState('');

  const handleSendMessage = useCallback(() => {
    if (value) {
      onSend?.(value);
      setValue('');
    }
  }, [onSend, setValue, value]);

  return (
    <Tooltip
      disabled={!disabled}
      placement="top"
      title="You can not send messages to the current channel"
    >
      <TextField
        multiline
        value={value}
        disabled={disabled}
        InputLabelProps={{ style: { pointerEvents: 'auto' } }}
        onChange={({ target }) => setValue(target.value)}
        onKeyDown={({ key, shiftKey }) =>
          !shiftKey && key === 'Enter' && handleSendMessage()
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                data-testid="masked-input-btn"
                disabled={!value}
                onClick={handleSendMessage}
                size="small"
              >
                <SvgIcon component={disabled ? ErrorIcon : SendIcon} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Tooltip>
  );
}

export default ChatMessagesInput;
