import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, QueryRoot, ReqMethod, Response } from 'src/services';
import {
  CHAT_GET_CHANNELS_API,
  CHAT_GET_SESSION_API,
  CHAT_ROOT_API,
  CHAT_SESSION_TAG,
  CHAT_STORE_API_KEY,
} from './constants';
import { ChatChannel, ChatSessionData, ChatSessionResponse } from './types';

export const chatApi = createApi({
  reducerPath: CHAT_STORE_API_KEY,
  baseQuery: baseQuery({ baseUrl: CHAT_ROOT_API, root: QueryRoot.Pa }),
  tagTypes: [CHAT_SESSION_TAG],
  refetchOnMountOrArgChange: true,
  endpoints: ({ query }) => ({
    getChatSession: query<ChatSessionData, void>({
      query: () => ({
        url: CHAT_GET_SESSION_API,
        method: ReqMethod.Get,
      }),
      providesTags: [CHAT_SESSION_TAG],
      transformResponse: ({
        data: { region, userArn, ...credentials },
      }: Response<ChatSessionResponse>) => ({
        userArn,
        region,
        credentials,
      }),
    }),
    getChatChannels: query<ChatChannel[], void>({
      query: () => ({
        url: CHAT_GET_CHANNELS_API,
        method: ReqMethod.Get,
      }),
      transformResponse: ({ data }) => data.channelMembershipSummaries,
    }),
  }),
});

export const { useGetChatSessionQuery, useGetChatChannelsQuery } = chatApi;
