import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  ChannelMessageSummary,
  ChimeSDKMessagingClient,
  ListChannelMessagesCommand,
  UpdateChannelReadMarkerCommand,
  SortOrder,
} from '@aws-sdk/client-chime-sdk-messaging';
import {
  DefaultMessagingSession,
  MessagingSessionObserver,
} from 'amazon-chime-sdk-js';
import { setChannelAction, setUsersColorsAction } from './actions';
import { chatApi } from './api';
import { CHAT_MESSAGES_PAGE_SIZE, CHAT_SESSION_TAG } from './constants';
import { ChatEventType, ChatSessionData } from './types';
import {
  getMessagingClient,
  getMessagingSession,
  getUserArnsFromMsgList,
} from './utils';

type NewMsgCallbackValue = ChannelMessageSummary & {
  ChannelArn: string;
};
type MessagingObserverParams = {
  session?: DefaultMessagingSession;
  onNewMessage?: (value: NewMsgCallbackValue) => void;
  onSessionStop?: () => void;
};

export const useMessagingObserver = (params: MessagingObserverParams) => {
  const { onNewMessage, onSessionStop, session } = params;

  useEffect(() => {
    const messagingObserver: MessagingSessionObserver = {
      messagingSessionDidReceiveMessage: ({ type, payload }) =>
        type === ChatEventType.CreateMessage &&
        onNewMessage?.(JSON.parse(payload)),
      messagingSessionDidStop: () => onSessionStop?.(),
    };
    session?.addObserver(messagingObserver);
    return () => session?.removeObserver(messagingObserver);
  }, [onNewMessage, onSessionStop, session]);
};

export const useMessagingSession = (params?: ChatSessionData) => {
  const dispatch = useDispatch();
  const client = useMemo(() => getMessagingClient(params), [params]);
  const session = useMemo(
    () => getMessagingSession(client, params?.userArn),
    [client, params?.userArn],
  );
  const onSessionStop = useCallback(
    () => dispatch(chatApi.util.invalidateTags([CHAT_SESSION_TAG])),
    [dispatch],
  );

  useEffect(() => {
    session?.start();
    return () => session?.stop();
  }, [session]);
  useMessagingObserver({ session, onSessionStop });

  return { client, session };
};

type UseMessagesListParams = {
  client?: ChimeSDKMessagingClient;
  session?: DefaultMessagingSession;
  userArn?: string;
  channelArn?: string;
};

export const useMessagesList = ({
  client,
  session,
  userArn,
  channelArn,
}: UseMessagesListParams) => {
  const lastMessageRef = useRef<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<ChannelMessageSummary[]>([]);
  const [nextToken, setNextToken] = useState<string | undefined>();

  const loadMessages = useCallback(
    async (isFirstPage?: boolean) => {
      if (client && (nextToken || isFirstPage)) {
        const command = new ListChannelMessagesCommand({
          ChimeBearer: userArn,
          ChannelArn: channelArn,
          NextToken: isFirstPage ? undefined : nextToken,
          MaxResults: CHAT_MESSAGES_PAGE_SIZE,
          SortOrder: SortOrder.DESCENDING,
        });
        setIsLoading(true);
        const { ChannelMessages = [], NextToken } = await client.send(command);
        setMessages((prevMessages) =>
          isFirstPage ? ChannelMessages : [...prevMessages, ...ChannelMessages],
        );
        setNextToken(NextToken);
        setIsLoading(false);
      }
    },
    [client, nextToken, userArn, channelArn],
  );
  const onNewMessage = useCallback(
    (newMessage: NewMsgCallbackValue) => {
      const { ChannelArn, MessageId } = newMessage;
      if (ChannelArn === channelArn && lastMessageRef.current !== MessageId) {
        setMessages((prevMessages) => [newMessage, ...prevMessages]);
        lastMessageRef.current = MessageId;
      }
    },
    [channelArn],
  );

  useMessagingObserver({ session, onNewMessage });
  useEffect(() => {
    setMessages([]);
    loadMessages(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelArn, userArn]);

  return { messages, loadMessages, isLoading };
};

type UseChannelsStateUpdateParams = UseMessagesListParams & {
  hasMessages?: boolean;
};

export const useChannelsStateUpdate = ({
  session,
  client,
  channelArn,
  userArn,
  hasMessages,
}: UseChannelsStateUpdateParams) => {
  const dispatch = useDispatch();
  const updateReadMarker = useCallback(
    () =>
      client?.send(
        new UpdateChannelReadMarkerCommand({
          ChannelArn: channelArn,
          ChimeBearer: userArn,
        }),
      ),
    [client, channelArn, userArn],
  );
  const onNewMessage = useCallback(
    (newMessage: NewMsgCallbackValue) => {
      const isCurrentChannelMsg = newMessage.ChannelArn === channelArn;
      dispatch(
        setChannelAction({
          channelArn: newMessage.ChannelArn,
          lastMessageTimestamp: newMessage.CreatedTimestamp?.toString(),
          hasNewMessages: !isCurrentChannelMsg,
        }),
      );
      if (isCurrentChannelMsg) {
        updateReadMarker();
      }
    },
    [channelArn, dispatch, updateReadMarker],
  );

  useMessagingObserver({ session, onNewMessage });
  useEffect(() => {
    if (channelArn && hasMessages) {
      updateReadMarker();
      dispatch(setChannelAction({ channelArn, hasNewMessages: false }));
    }
  }, [dispatch, channelArn, hasMessages, updateReadMarker]);
};

export const useSetupUsersColors = (
  messages: ChannelMessageSummary[],
  ownUserArn?: string,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (messages && ownUserArn) {
      const userArnArray = getUserArnsFromMsgList(messages, ownUserArn);
      dispatch(setUsersColorsAction(userArnArray));
    }
  }, [dispatch, messages, ownUserArn]);
};
